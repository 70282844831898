<template>
  <div class="html">

    <Head :type="0">

      <template #title>
        <div>确认订单</div>
      </template>

    </Head>


    <div class="main">

      <!-- 无收货地址时 -->
      <div class="empty" v-show="!address"> <img src="../../../assets/maker/jia_gery.png"
          @click="$router.push('shippingAddress')" />
        <div>点击添加收货地址</div>
      </div>


      <!-- 有地址时 -->
      <div class="address" v-if="address" @click="$router.push('shippingAddress')">


        <div class="titel">

          <!-- 是否时默认地址图标 -->
          <div class="lable" v-show="address.isDefault === '1'">默认</div>


          <!-- 非直辖市地址 -->
          <div v-if="address.provinceName != address.cityName"> {{ address.provinceName + "&ensp;" + address.cityName +
            "&ensp;" + address.countyName }} </div>

          <!--  直辖市地址 -->
          <div v-if="address.provinceName === address.cityName"> {{ address.cityName + "&ensp;" + address.countyName }}
          </div>

        </div>

        <!-- 收货详细地址 -->
        <div class="middel">{{ address.detailInfo }}</div>


        <!-- 收货人姓名   电话 -->
        <div class="info">
          <div class="name">{{ address.userName }}</div> {{ address.telNum }}
        </div>

        <!-- 箭头图片 -->
        <img src="../../../assets/img/right_gery.png" alt="" />

      </div>

      <!-- 按店铺渲染区域 -->
      <div class="shop" v-for="item in orderData" :key="item.id">

        <!-- 店铺名 -->
        <div class="shop-name">{{ item.storeBasic.storeName }}</div>

        <!--  商品渲染区域 -->
        <div class="warp" v-for="(li, index) in item.goodArr" :key="li.makerProduct.id + index">


          <!--  单个区域 -->
          <div class="good-box">

            <!-- 商品图片 -->
            <img :src="li.picUrl" alt="" />

            <!-- 商品信息 -->
            <div class="goods-info">


              <div class="title">

                <!-- 商品名 -->
                <div class="name"> {{ li.spuName }} </div>

                <!--  商品价格 -->
                <div class="price"> <span>￥</span>{{ li.addPrice}}</div>

              </div>

              <!--  商品规格区域 -->
              <div class="lable-box">

                <div class="wrap">

                  <!-- 商品规格 -->
                  <div class="lable" v-for="info,index in li.makerProductSkuAttributes" :key="info.id" v-show="index<2">
                    {{info.attributeValue}} </div>

                </div>

                <!-- 商品数量 -->
                <span>x{{ li.quantity }}</span>
              </div>


            </div>

          </div>

          <div class="way">
            <div>配送方式</div><span>普通快递</span>
          </div>

          <div class="way">
            <div>运费：</div><span>{{li.freight}}元</span>
          </div>

          <div class="way">
            <div>商品备注</div><input type="text" v-model="li.remark" placeholder="选填  请先和商家协调一致" />
          </div>


        </div>


        <!--  商品数量及总价 -->
        <div class="total">
          <p>共{{ item.goodNum }}件商品 小计：</p> <span>￥</span>
          <div>{{ item.priceAll }}</div>
        </div>




      </div>
      <div class="goods-price">
        <div>运费总计</div>
        <p>￥{{ freight }}</p>
      </div>

      <div class="goods-price">
        <div>商品总额</div>
        <p>￥{{ priceAll }}</p>
      </div>

      <ul class="payway">
        <!-- <li>
                      <div class="left">
                        <img src="../../../assets/maker/qianbao.png" alt="" /> 钱包支付
                      </div>
                      <van-switch v-model="checked" size="0.5rem" />
                    </li> -->

        <li>
          <div class="left" @click="pay = 1">

            <img src="../../../assets/img/alipay-icon.png" alt="" /> 支付宝
          </div>

          <img src="../../../assets/img/checkout.png" v-show="pay !== 1" class="check" />

          <img src="../../../assets/maker/check_in.png" v-show="pay === 1" class="check" />

        </li>

        <li>
          <div class="left" @click="pay = 2">

            <img src="../../../../public/img/weixin.png" alt="" /> 微信
          </div>


          <img src="../../../assets/img/checkout.png" v-show="pay !== 2" class="check" />


          <img src="../../../assets/maker/check_in.png" v-show="pay === 2" class="check" />


        </li>

      </ul>
    </div>
    <div class="footer">
      <div class="up"> 合计： ￥{{ priceAll+freight }} <div class="login" @click="showPay()">提交订单</div>
      </div>
    </div>

    <!--  loading -->
    <van-loading v-show="show" type="spinner" />







  </div>
</template>

<script>
  import Head from "../../../components/head/index.vue"; // 公共头部
  import { getAddress, upOrder, getFreight } from "../../../api/maker/order"; // 地址  下单  运费接口
  import { wxPay, aliPay } from "../../../api/maker/pay";  // 微信 支付宝  支付接口
  //
  export default {
    data() {
      return {

        show: false,  // loading 的状态

        // checked: true, //  钱包的是否使用

        orderData: JSON.parse(sessionStorage.getItem("goodList")), // 商品二级列表  //带店铺

        address: null, // 地址信息

        pay: 1, // 支付方式 1支付宝 2微信

        arr: [], // 商品 一级列表

        freight: 0 // 运费总计

      };
    },

    created() {
      this.setRem()
      this.getArr()

      try {

        this.address = JSON.parse(sessionStorage.getItem("address")) ?? ""; // 判断是否在地址列表选择地址

        sessionStorage.removeItem("address"); // 清楚缓存

      } catch (err) {

        //

      }

      // 判断是否在地址列表页里选择地址
      if (!this.address) {

        // 获取地址列表
        getAddress().then((res) => {


          if (res.data.code === 0) {

            // 判断地址列表是否为空
            if (res.data.data.records.length < 1) {
              return;
            }

            // 查找默认地址  无默认地址 选择第一个地址
            res.data.data.records.forEach((item, index) => {

              if (item.isDefault === "1" || index === 0) {

                this.address = item;

              }
            });
          }
        });

      }

    },


    watch: {
      // 监控收货地址是否切换
      address: function (newData) {

        // 判断是否有新地址
        if (newData) {

          //当有新地址时 重新请求接口 获取每件商品的运费
          getFreight({ userAddressId: newData.id, skus: [...this.arr], paymentType: 'APP' }).then(res => {

            if (res.data.code == 0) {


              let i = 0

              // 将运费添加到每件商品的的freight字段上
              this.orderData.forEach(item => {

                item.goodArr.forEach((li) => {

                  this.$set(li, 'freight', res.data.data[i] / 10);

                  i++

                })

              })
            }

            // 清空运费总计
            this.freight = 0


            // 获取运费总计
            res.data.data.forEach(item => {

              this.freight += item

            })

            this.freight /= 10
          })

        }
      }

    },
    methods: {

      // 下单方法
      showPay() {

        // 判断是否有收货地址
        if (!this.address) {

          this.$toast('请选择收货地址')

          return

        }


        // 显示loading防止用户多次点击
        this.show = true

        // 判断姓名是否少于两个字  小于两个字无法下单
        if (this.address.userName.length < 2) {

          this.$toast.fail('姓名不能少于2个字')

          return

        }


        // 获取商品信息
        this.getArr()

        // 下单接口
        upOrder({ userAddressId: this.address.id, skus: [...this.arr], paymentType: 'APP' }).then((res) => {




          if (res.data.code === 0) {

            // 确认已下单

            let arr = res.data.data.map(item => item.id) // 获取订单ID


            if (this.pay == 1) {

              aliPay(arr).then((res) => {

                this.openPay(res, '2');

              });

              return
            }




            wxPay(arr).then((res) => {

              this.openPay(res, '1');

            });


          } else {


            this.$toast.fail("下单失败");

          }
        }
        ).finally(() => {
          //下单请求返回  关闭loading
          this.show = false
        })
      },

      closePage() {

        this.$router.$goBack()

        // this.$store.commit('delRouter')
        // this.$router.replace({ 
        //   path  : 'makerOrder',
        //   query : {  type:1
        //   }
        // })
        // if(data!=1){ 
        //       sessionStorage.setItem("makerStatus", true);
        // }

        // 保存成功下单状态



      },

      getArr() {
        this.arr = []
        // 获取商品信息
        this.orderData.forEach((item) => {

          item.goodArr.forEach((li) => {
            // let cpecArr=[]
            //   li.makerProductSkuAttributes.forEach(info=>{ 
            //     cpecArr.push(info.attributeValue)
            //   })
            if (!Object.prototype.hasOwnProperty.call(li, 'remark')) {
              this.$set(li, 'remark', '');

            }
            this.arr.push({

              makerShopId: li.makerShopId, //店铺ID

              spuId: li.spuId ?? null, //商品规格ID

              skuId: li.skuId ?? null, //商品ID

              quantity: li.quantity, // 商品数量

              picUrl: li.picUrl ?? null, // 商品图片

              specInfo: li.makerProductSkuAttributes?.map(item => item.attributeValue).join(' ') ?? null, // 商品规格

              remark: li.remark ?? null, // 备注

            });

          });

        });
      }
    },
    computed: {
      priceAll: {
        get() {
          let sum = 0;
          this.orderData.forEach((item) => {
            sum += item.priceAll * 100;
          });
          return sum / 100;
        },
      },
    },
    components: { Head },

  };
</script>

<style lang="less" scoped>
  .van-loading {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 200;
    transform: translate(-50%, -50%);
  }

  .html {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    z-index: 80;
    background-color: white;

    .main {
      flex: 1;
      background-color: #f6f6f6;
      padding: 0.266667rem;
      overflow: auto;

      //  margin-top: .566667rem;
      .empty {
        border-radius: 0.266667rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #999;
        font-size: 0.373333rem;
        margin-bottom: 0.266667rem;
        height: 1.866667rem;

        img {
          width: 0.64rem;
          height: 0.64rem;
          margin-bottom: 0.133333rem;
        }
      }

      .address {
        background-color: white;
        border-radius: 0.266667rem;
        padding: 0.266667rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 0.32rem;
        color: #333333;
        position: relative;
        margin-bottom: 0.266667rem;

        .titel {
          display: flex;
          align-items: center;
          margin-bottom: 0.213333rem;

          .lable {
            width: 0.746667rem;
            height: 0.453333rem;
            margin-right: 0.266667rem;
            border-radius: 0.08rem;
            background-color: red;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .middel {
          font-size: 0.373333rem;
          color: #333;
          margin-bottom: 0.106667rem;
        }

        .info {
          display: flex;
          align-items: center;
          color: #999;
          font-family: PingFangSC-Regular, PingFang SC;

          .name {
            margin-right: 0.266667rem;
          }
        }

        img {
          position: absolute;
          right: 0.466667rem;
          top: 50%;
          transform: translateY(-50%);
          width: 0.4rem;
          height: 0.533333rem;
        }
      }

      .shop {
        background-color: white;
        border-radius: 0.266667rem;
        padding: 0.266667rem;
        margin-bottom: .266667rem;

        .shop-name {
          font-family: PingFangSC-Medium, PingFang SC;
          font-size: 0.373333rem;
          color: #333;
          margin-bottom: 0.48rem;
        }

        .warp {
          padding-bottom: 0.4rem;

        }

        .good-box {
          display: flex;
          margin-bottom: 0.4rem;

          img {
            height: 2.133333rem;
            width: 2.133333rem;
            border-radius: 0.133333rem;
            justify-content: space-between;
          }

          .goods-info {
            flex: 1;
            padding-left: 0.266667rem;
            display: flex;
            flex-direction: column;

            .title {
              display: flex;
              flex: 1;
            }

            .name {
              font-size: 0.32rem;
              box-sizing: border-box;
              color: #333;
              width: 80%;
              height: 100%;
            }

            .lable-box {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-shrink: 0;
              flex-wrap: nowrap;
            }

            .wrap::-webkit-scrollbar {
              display: none;
            }

            .wrap {
              display: flex;
              align-items: center;
              flex-shrink: 0;
              overflow-x: auto;
              box-sizing: border-box;
              width: 5.333333rem;
              flex-shrink: 0;

            }

            .lable {
              font-size: 0.373333rem;
              color: #999;
              display: flex;
              justify-content: space-between;
              background-color: #f1f1f1;
              margin-right: .133333rem;
              padding: 0 .133333rem;
              white-space: nowrap;
              border-radius: .066667rem;

            }

            .price {
              color: #a80000;
              font-size: 0.48rem;
              flex: 1;
              text-align: center;

              span {
                font-size: 0.373333rem;
              }
            }
          }
        }

        .way {
          margin-bottom: 0.4rem;
          color: #333;
          font-size: 0.373333rem;
          display: flex;


          div {
            margin-right: 0.4rem;
            width: 2rem;
          }

          input {
            flex: 1;
            box-sizing: border-box;
          }

          input ::-webkit-input-placeholder {
            color: #f6f6f6;
            font-size: 0.32rem;
          }
        }

        .total {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          font-size: 0.373333rem;
          color: #333;

          span {
            font-size: 0.32rem;
            color: #a80000;
            font-size: 0.373333rem;
          }

          div {
            color: #a80000;
            font-size: 0.48rem;
            line-height: 0.65rem;
          }
        }
      }

      .goods-price {
        padding: 0.266667rem 0.293333rem;
        border-radius: 0.266667rem;
        display: flex;
        justify-content: space-between;
        font-size: 0.373333rem;
        background-color: white;
        margin-top: 0.266667rem;

        div {
          color: #333;
        }

        p {
          color: #a80000;
        }
      }

      .payway {
        background-color: white;
        margin-top: 0.266667rem;
        padding: 0.266667rem;
        border-radius: 0.266667rem;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.4rem 0;
          border-bottom: 1px solid #f6f6f6;

          .left {
            display: flex;
            align-items: center;
            font-size: .373333rem;

            flex: 1;

            img {
              width: 0.64rem;
              height: 0.64rem;
              margin-right: 0.266667rem;
            }
          }

          .check {
            width: 0.526667rem;
            height: 0.526667rem;
          }
        }

        li:nth-last-of-type(1) {
          border: none;
        }
      }
    }

    .footer {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);

      .up {
        padding: 0.266667rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #333;
        font-size: 0.373333rem;
        margin-right: .133333rem;

        .login {
          width: 2.426667rem;
          height: 0.96rem;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: linear-gradient(to right, #ff7200, #ff3c00);
          border-radius: 0.48rem;
          margin-left: 0.266667rem;
        }
      }
    }
  }
</style>