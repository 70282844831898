import { fetchPost} from '../../router/https'

function wxPay(data){ 
    return fetchPost('/createrapp/makerorderinfo/unifiedOrder',data)
}
function  aliPay(data){ 
    return fetchPost('/createrapp/makerorderinfo/appAlipay',data)
}

export{ 
    wxPay, //创客微信支付
    aliPay//创客支付宝支付
}